<template>
    <div class="marquee-container">
        <div class="marquee-wrapper" ref="marqueeWrapper">
            <div class="marquee-content" ref="marqueeContent">{{ text }}</div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        },
        // 滚动速度，单位为像素/秒
        speed: {
            type: Number,
            default: 100
        }
    },
    data() {
        return {
            wrapperWidth: 0,
            contentWidth: 0,
            animationDuration: 0
        };
    },
    mounted() {
        this.wrapperWidth = this.$refs.marqueeWrapper.offsetWidth;
        this.contentWidth = this.$refs.marqueeContent.offsetWidth;
        this.animationDuration = this.contentWidth / this.speed;
        if (this.contentWidth > this.wrapperWidth) {
            this.startMarquee();
        }
    },
    methods: {
        //去除空格
        trimAll(ele) {
            if (typeof ele === 'string') {
                return ele.split(/[\t\r\f\n\s]*/g).join('');

            }
        },
        startMarquee() {
            const animation = this.$refs.marqueeContent.animate(
                [
                    { transform: "translateX(0)" },
                    { transform: `translateX(-${this.contentWidth}px)` }
                ],
                {
                    duration: this.animationDuration * 1000,
                    iterations: Infinity
                }
            );
            animation.pause();
            setTimeout(() => {
                animation.play();
            }, 1000);
        }
    }
};
</script>
  
<style>
.marquee-container {
    overflow: hidden;
    white-space: nowrap;
}

.marquee-wrapper {
    width: 100%;

}

.marquee-content {
    display: inline-block;
    animation-timing-function: linear;
}
</style>
  